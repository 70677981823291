<template>
  <b-card
    class="purchaseoutbound-edit-wrapper"
  >
    <!-- form -->
    <b-form id="purchaseoutboundForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退料出库单编号"
            label-for="outbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="outbound_no"
              size="sm"
              v-model="purchaseoutbound.outbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库仓仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="purchaseoutbound.warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="入库仓仓库名称"
            label-for="warehouse_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_name"
              size="sm"
              v-model="purchaseoutbound.warehouse_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退货原因  purchase_returnbound_reason"
            label-for="reason"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="reason"
              size="sm"
              v-model="purchaseoutbound.reason"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="ID"
            label-for="returnbound_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnbound_id"
              size="sm"
              v-model="purchaseoutbound.returnbound_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购退货单编号"
            label-for="returnbound_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="returnbound_no"
              size="sm"
              v-model="purchaseoutbound.returnbound_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单ID   purchase_order.order_id"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="purchaseoutbound.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单编号  purchase_order.order_no"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="purchaseoutbound.order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供应商ID"
            label-for="supplier_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="supplier_id"
              size="sm"
              v-model="purchaseoutbound.supplier_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供应商名称"
            label-for="supplier_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="supplier_name"
              size="sm"
              v-model="purchaseoutbound.supplier_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供应商联系人"
            label-for="supplier_contact_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="supplier_contact_name"
              size="sm"
              v-model="purchaseoutbound.supplier_contact_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="供应商联系方式"
            label-for="supplier_contact_mobile"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="supplier_contact_mobile"
              size="sm"
              v-model="purchaseoutbound.supplier_contact_mobile"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="退货日期"
            label-for="return_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="return_time"
              size="sm"
              v-model="purchaseoutbound.return_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购退货费用承担方 purchase_returnbound_responsibility"
            label-for="responsibility"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="responsibility"
              size="sm"
              v-model="purchaseoutbound.responsibility"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="物流方式  delivery_method_purchase_returnbound"
            label-for="delivery_method"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="delivery_method"
              size="sm"
              v-model="purchaseoutbound.delivery_method"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="车辆类型 car_type"
            label-for="car_type"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="car_type"
              size="sm"
              v-model="purchaseoutbound.car_type"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="车辆外形 car_detail"
            label-for="car_detail"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="car_detail"
              size="sm"
              v-model="purchaseoutbound.car_detail"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="车牌号"
            label-for="car_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="car_no"
              size="sm"
              v-model="purchaseoutbound.car_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="司机联系电话"
            label-for="car_phone"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="car_phone"
              size="sm"
              v-model="purchaseoutbound.car_phone"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="司机"
            label-for="car_driver"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="car_driver"
              size="sm"
              v-model="purchaseoutbound.car_driver"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="运输费"
            label-for="car_carriage"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="car_carriage"
              size="sm"
              v-model="purchaseoutbound.car_carriage"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="说明"
            label-for="remark"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="remark"
              size="sm"
              v-model="purchaseoutbound.remark"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="附件"
            label-for="attachments"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="attachments"
              size="sm"
              v-model="purchaseoutbound.attachments"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购退料出库单审核状态  purchase_outbound_status"
            label-for="status"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="status"
              size="sm"
              v-model="purchaseoutbound.status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="purchaseoutbound.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建时间"
            label-for="add_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="add_time"
              size="sm"
              v-model="purchaseoutbound.add_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="创建人"
            label-for="creator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="creator"
              size="sm"
              v-model="purchaseoutbound.creator"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改时间"
            label-for="modify_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="modify_time"
              size="sm"
              v-model="purchaseoutbound.modify_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="修改人"
            label-for="updator"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="updator"
              size="sm"
              v-model="purchaseoutbound.updator"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchaseoutboundStore from './purchaseoutboundStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      purchaseoutbound: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('purchaseoutbound')) store.registerModule('purchaseoutbound', purchaseoutboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseoutbound')) store.unregisterModule('purchaseoutbound')
    })

    const edit = function() {
      store.dispatch('purchaseoutbound/edit', {id: this.id}).then(res => {
        this.purchaseoutbound = res.data.data
      })
    }

    const view = function() {
      store.dispatch('purchaseoutbound/view', {id: this.id}).then(res => {
        this.purchaseoutbound = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('purchaseoutbound/save', this.purchaseoutbound).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>